<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.stock_out_approve') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.organization')"
              label-for="org_id"
              >
              <b-form-select
                plain
                v-model="search.org_id"
                :options="orgList"
                id="org_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.office')"
              label-for="office_id"
              >
              <b-form-select
                plain
                v-model="search.office_id"
                :options="officeList"
                id="office_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
              <b-form-input
                class="date-picker"
                 v-model="search.from_date"
                placeholder="Select Date"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.to_date')"
              label-for="to_date"
            >
              <b-form-input
                class="date-picker"
                 v-model="search.to_date"
                placeholder="Select Date"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.stock_out') + ' ' + $t('globalTrans.id')"
              label-for="nid"
            >
              <b-form-input
                plain
                id="nid"
                v-model="search.stock_out_id"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.stock_out_approve') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table-head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(affidavit_bn)="data">
                      <p v-html="data.item.affidavit_bn"></p>
                    </template>
                    <template v-slot:cell(affidavit)="data">
                      <p v-html="data.item.affidavit"></p>
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                       <span class="badge badge-danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</span>
                        <span class="badge badge-success" v-else>{{ $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(stock_out_date)="data">
                      {{ data.item.stock_out_date | dateFormat }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" title="View" class="btn_table_action table_action_view" v-b-modal.modal-1 @click="edit(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" title="Approve" class="btn_table_action table_action_status" v-if="(itemStatus(data.item) === 0)" @click="remove(data.item)"><i class="fas fa-check"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="lg" :title="this.$t('pump_install.stock_out') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Detail :id="itemId" :key="itemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Detail from './Detail'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { stockOutList, stockOutApprove } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  components: {
    Detail
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: 0,
        office_id: 0,
        stock_out_id: '',
        to_date: '',
        from_date: ''
      },
      itemId: 0,
      rows: [],
      officeList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.IrriPumpInstall.stockOutList
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('pump_install.stock_out') + ' ' + this.$t('globalTrans.id'), class: 'text-center' },
          { label: this.$t('pump_install.stock_out') + ' ' + this.$t('globalTrans.date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'stock_out_id' },
          { key: 'stock_out_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'stock_out_id' },
          { key: 'stock_out_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    orgList: function () {
     return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal, oldVal) {
      if (newVal) {
        this.search.office_id = 0
        this.officeList = this.getOfficeList(newVal)
      }
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    flatpickr('.date-picker', {})
    this.loadData()
  },
  methods: {
    resetId () {
      this.itemId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        org_id: '0'
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    edit (item) {
      this.itemId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${stockOutApprove}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('IrriPumpInstall/stockOutApprove', item)
          this.$toast.success({
            title: 'Success',
            message: 'Stock Out Approved Successfully',
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: response.message
          })
        }
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('pump_install.are_you_sure_to_approve'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, stockOutList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('IrriPumpInstall/stockOutList', this.dataList(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$toast.error({
            title: 'Error',
            message: response.message,
            color: '#D6E09B'
          })
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    dataList (data) {
      const orgList = this.$store.state.commonObj.organizationProfileList
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
        const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    },
    getOfficeList (orgId) {
      const officeList = this.$store.state.commonObj.officeList
      if (orgId) {
        return officeList.filter(item => item.org_id === orgId)
      } else {
        return officeList
      }
    },
    itemStatus (item) {
      return item.status
    }
  }
}
</script>
